<template>
  <b-card :class="{invalid: coupon.used}" class="coupon">
    <b-row align-v="end">
      <b-col class="coupon-info">
        <h4 v-if="coupon.extra && coupon.extra.cashback">返现优惠券</h4>
        <h4 v-else-if="coupon.activity && coupon.activity.name">{{coupon.activity.name}}</h4>
        <h4 v-else-if="coupon.name">
          <fa icon="star" class="text-warning" v-if="coupon.isPublic"/>
          {{coupon.name}}
        </h4>
        <h4 v-else>普通优惠券</h4>

        <datetime :value="coupon.expireAt" prefix="有效期至 " class="small"/>
      </b-col>
      <b-col cols="auto" class="text-right">
        <div class="coupon-value">
          <span class="price">{{couponPrice}}</span>
          <small>{{coupon.price >= 1 ? '元' : '折'}}</small>
        </div>
        <div class="small limit">
          <span v-if="coupon.minUsePrice">满 {{coupon.minUsePrice}} 可用</span>
          <span v-else>无门槛</span>
        </div>
      </b-col>
    </b-row>
    <hr>
    <footer>
      <b-row align-v="center">
        <b-col class="text-muted small">
          <span class="limit" v-if="coupon.products.length">
            {{coupon.products | showProducts}}
          </span>
          <span class="limit" v-if="coupon.binding.length">
            {{coupon.binding | showBindings}}
          </span>
          <span class="limit" v-if="coupon.publishTypes.length">
            {{coupon.publishTypes | showPublishTypes}}
          </span>
          <span class="no-limit" v-if="!coupon.products.length && !coupon.binding.length">
            无使用限制
          </span>
        </b-col>
        <b-col cols="auto">
          <span class="text-muted" v-if="coupon.used">已使用</span>
          <b-btn variant="outline-primary" size="sm" :to="useUrl" v-else>去使用</b-btn>
        </b-col>
      </b-row>
    </footer>
  </b-card>
</template>

<script>
import { round } from 'lodash'
import { isAlbum } from '@/models/book'

export default {
  name: 'coupon',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    useUrl() {
      if (this.coupon.products.length === 1) {
        const product = this.coupon.products[0]
        if (isAlbum(product)) {
          return '/albums?tab=products&product=' + product
        }
        return '/create/' + product
      }
      return '/books'
    },
    coupon() {
      return this.value
    },
    couponPrice() {
      const val = this.coupon.price
      if (val < 1) {
        return round(val * 10, 2)
      }
      return round(val, 2)
    }
  }
}
</script>

<style scoped lang="scss">
.coupon {
  position: relative;

  .coupon-value {
    color: $primary;
    text-align: right;
    white-space: nowrap;
    line-height: 1;
    margin-top: -.25em;
    margin-bottom: .25em;
  }

  .limit {
    font-size: 12px;
  }

  .price {
    font-size: 2em;
    margin-right: -3px;
  }

  hr {
    border-style: dashed;
    margin: 10px auto;
  }
}
</style>
