<template>
  <div class="claim-coupon">
    <loading/>
  </div>
</template>

<script>
export default {
  name: 'ClaimCoupon',
  title: '领取优惠券',
  beforeRouteEnter(to, from, next) {
    let url = '/my/promotions'
    if (to.params.couponNo) {
      url += '?couponNo=' + to.params.couponNo
    }
    next(url)
  }
}
</script>

<style scoped lang="scss">
  .claim-coupon {
    max-width: 480px;
    padding: 0 1rem;
    .coupon {
      border-radius: 4px;
      box-shadow: $shadow-base;
      margin-bottom: 1em;
      border: 0;
      overflow: hidden;
      background-color: $primary;
      &.invalid {
        background-color: #d9d9d9;
      }
      &.claimed {
        background-color: $success;
      }
      .card-body, .card-footer {
        position: relative;
      }
      .card-body {
        padding-top: 1em;
        padding-bottom: 1em;
        color: #fff;
      }
      .card-footer {
        border-top: 0;
        background-color: #fff;
      }

      .coupon-value {
        white-space: nowrap;
      }

      .price {
        line-height: 1.2;
        font-size: 2.5em;
      }

      .intro {
        font-size: 1.5em;
      }

      &.activity-coupon {
        color: #fff;
        .price {
          font-size: 3.5em;
        }
        .card-footer:after {
          content: '';
          display: block;
          position: absolute;
          top: -4px;
          left: 0;
          width: 100%;
          height: 4px;
          background-image: url('../../assets/images/coupon-border.png');
          background-repeat: repeat-x;
          background-position: 0 0;
          background-size: 12px 4px;
        }
      }
      &.my-coupon {
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 4px;
          background-image: url('../../assets/images/coupon-border-y.png');
          background-repeat: repeat-y;
          background-position: 0 0;
          background-size: 3px 18px;
          z-index: 10;
        }
        .coupon-value {
          max-width: 6.5em;
        }
        .coupon-info {
          padding-top: 1em;
          padding-bottom: 1em;
          padding-right: 0;
          min-width: 0; // 保持宽度，文字超限时不撑开
        }
        .price {
          display: inline-block;
          min-width: 40px;
        }
        .decoration {
          position: relative;
          min-width: 9px;
          max-width: 9px;
          padding: 0;
          overflow: hidden;
          margin-top: -1em;
          margin-bottom: -1em;
          .line {
            position: absolute;
            left: 4px;
            top: 10px;
            bottom: 10px;
            width: 1px;
            border-left: 1px dashed #fff;
          }
          &:before, &:after {
            content: '';
            position: absolute;
            display: block;
            background: #fff;
            width: 9px;
            height: 9px;
            border-radius: 50%;
          }
          &:before {
            top: -4.5px;
          }
          &:after {
            bottom: -4.5px;
          }
        }
        .centered {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }
    }
  }
</style>
