<template>
  <div class="redeem-codes">
    <div class="list" v-if="redeemCodes.length">
      <divider class="mb-3">以下是您的兑换码</divider>
      <b-card v-for="item in redeemCodes" :key="item.code">
        <div class="title mb-1">
          <div class="float-right" v-if="item.note">{{item.note}}</div>
          <h4 class="mb-0">兑换码 {{item.code}}</h4>
        </div>
        <b-row align-v="center">
          <b-col>
            <div>产品: {{item.tid | productName}}</div>
            <div v-if="item.extra.price || item.extra.shipMoney">
              <span class="mr-2" v-if="item.extra.price">价格：{{item.extra.price}}元</span>
              <span v-if="item.extra.shipMoney">邮费：{{item.extra.shipMoney}}元</span>
            </div>
            <div>
              获取时间:
              <datetime :value="item.createTime" format="YYYY/MM/DD HH:mm:ss"/>
            </div>
            <div v-if="item.redeemTime">
              已兑换于:
              <datetime :value="item.redeemTime"/>
            </div>
            <div v-else>
              兑换截止:
              <span v-if="!item.expireAt">永不过期</span>
              <datetime :value="item.expireAt" v-else/>
            </div>
          </b-col>
          <b-col cols="auto">
            <b-btn v-if="item.orderId" :to="'/orders/' + item.orderId">查看订单</b-btn>
            <b-btn variant="outline-primary" v-else-if="item.aid" :to="'/albums/' + item.aid">
              查看作品
            </b-btn>
            <b-btn variant="primary" :to="'/redeem?code=' + item.code" v-else-if="!item.hasExpired">
              前往兑换
            </b-btn>
            <b-btn disabled v-else>已过期</b-btn>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'

export default {
  name: 'redeemCodes',
  title: '我的兑换码',
  mixins: [routeData('redeemCodes')],
  methods: {
    onLoad() {
      this.redeemCodes = this.redeemCodes.filter(i => !i.hidden)
    }
  }
}
</script>

<style lang="scss">
.redeem-codes {

}
</style>
