<template>
  <div class="reset">
    <form @submit.prevent="onSubmit">
      <b-card title="修改密码">
        <b-form-group label="确认手机号">
          <b-input type="tel" placeholder="请输入您的手机号" maxlength="11" required v-model="phone"/>
        </b-form-group>
        <b-form-group label="旧密码">
          <b-input type="password" placeholder="请输入您的旧密码" v-model="oldPassword" required/>
        </b-form-group>
        <b-form-group label="新密码">
          <b-input type="password" v-model="password" minlength="6" maxlength="20"
                   :disabled="!oldPassword" placeholder="请输入您要设置的新密码" required/>
        </b-form-group>
        <b-form-group label="确认新密码">
          <b-input type="password" v-model="passwordRepeat" minlength="6" maxlength="20"
                   :disabled="!oldPassword" placeholder="确认新密码" required/>
        </b-form-group>

        <div class="btn-area">
          <b-btn variant="primary" block type="submit">提交</b-btn>
        </div>
      </b-card>
    </form>
  </div>
</template>

<script>
export default {
  name: 'reset',
  title: '修改密码',
  data() {
    return {
      phone: '',
      oldPassword: '',
      password: '',
      passwordRepeat: ''
    }
  },
  methods: {
    onSubmit() {
      if (this.password !== this.passwordRepeat) {
        this.$alert.warn('两次输入的密码不相同，请检查后再试')
        return
      }
      if (this.password === this.oldPassword) {
        this.$alert.warn('请设置一个不同的密码哦')
        return
      }
      if (this.password.length < 6) {
        this.$alert.warn('密码不能小于6位，请重新输入新密码')
        return
      }
      return this.$req.post('/api/mobile/user/password/reset', {
        loginPhone: this.phone,
        oldPassword: this.oldPassword,
        password: this.password
      }).then(() => {
        this.$alert.success('修改成功，请重新登录')
        this.logout()
      })
    },
    logout() {
      this.$store.commit('resetAuth')
      if (this.$route.meta.private) {
        this.$router.push('/signin')
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .reset.content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
</style>
