<template>
  <div class="my-coupons">
    <div class="coupons" v-if="loading">
      <b-card v-for="coupon in 3" :key="coupon">
        <ske width="5em" style="font-size: 1.5rem;"></ske>
        <div>
          <ske width="9em"></ske>
        </div>
        <hr style="border-style: dashed;">
        <ske width="5em" class="small"></ske>
      </b-card>
    </div>
    <template v-else>
      <div class="coupons" v-if="coupons.length">
        <coupon v-for="coupon in coupons" :key="coupon.couponNo" :value="coupon"/>
      </div>
      <b-card class="text-center" v-else>
        <empty emotion="cry">暂无已领取的优惠券哦</empty>
        <p>分享邀请海报，即可换来最低 9 折优惠券</p>
        <p class="btn-area">
          <b-button block to="/my/referral?poster" variant="primary">去看看</b-button>
        </p>
      </b-card>
    </template>
  </div>
</template>

<script>
import { reverse } from 'lodash'
import routeData from '@/mixins/route-data'
import Coupon from '@/components/Coupon'

export default {
  name: 'coupons',
  components: {Coupon},
  title: '我的优惠券',
  data() {
    return {
      tabIndex: 1
    }
  },
  mixins: [routeData('coupons')],
  methods: {
    onLoad() {
      this.coupons = reverse(this.coupons)
    }
  }
}
</script>

<style lang="scss">
.my-coupons {
  .coupons {
    margin-top: 1em;
  }

  .limit {
    & + .limit {
      &:before {
        content: '；';
      }
    }

    & ~ .no-limit {
      display: none;
    }
  }

  .coupon {
    position: relative;

    .card-body {
      padding-bottom: 1em;
    }

    .coupon-value {
      color: $primary;
      text-align: right;
      white-space: nowrap;
      line-height: 1;
      margin-top: -.25em;
      margin-bottom: .25em;
    }

    .limit {
      font-size: 12px;
    }

    .price {
      font-size: 2em;
      margin-right: -3px;
    }

    .divider {
      position: relative;
      overflow: hidden;
      padding-top: .5rem;
      padding-bottom: .5rem;

      hr {
        border-top: 1px dashed #ccc;
        margin: 0;
      }
    }

    footer {
      position: relative;
    }
  }
}
</style>
