<template>
  <div class="balance">
    <b-card class="my-balance">
      <div class="row align-items-center">
        <b-col>
          <div>我的余额（元）</div>
          <div class="balance-value">
            <ske v-if="loading" width="4em"></ske>
            <template v-else>{{balance.balance | round(2)}}</template>
          </div>
        </b-col>
        <b-col cols="auto">
          <b-btn to="/my/balance/details" variant="link">
            <fa icon="list"/>
            明细
          </b-btn>
          <b-btn variant="primary" to="/my/recharge">
            <fa icon="coins"/>
            充值
          </b-btn>
        </b-col>
      </div>
    </b-card>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'

export default {
  name: 'balance',
  title: '我的余额',
  mixins: [routeData('balance')],
  data() {
    return {
      showDetails: !!this.$route.query.detail,
      active: Number(this.$route.query.id)
    }
  }
}
</script>

<style scoped lang="scss">
  .balance {
    .my-balance {
      margin-bottom: 1em;
      .balance-value {
        color: $primary;
        font-size: 2em;
      }
    }
    .balance-detail {
      background-color: #fff;
      padding: 1em;
    }
  }
</style>
