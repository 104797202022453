<template>
  <div class="bind">
    <form @submit.prevent="onSubmit" name="reset">
      <b-card title="绑定手机号">
        <b-form-group label="手机号">
          <b-input type="tel" placeholder="请输入您的手机号" maxlength="11" required v-model="phone"/>
        </b-form-group>
        <b-form-group label="验证码">
          <b-input-group>
            <b-input v-model="code" placeholder="请输入验证码" maxlength="4" required/>
            <b-btn @click.prevent="sendVerify" slot="append" :disabled="!phone">{{sendBtnText}}</b-btn>
          </b-input-group>
        </b-form-group>

        <b-form-group label="新密码">
          <b-input type="password" v-model="password" minlength="6" maxlength="20"
                   placeholder="请输入您的登录密码" required/>
        </b-form-group>
        <b-form-group label="重复新密码">
          <b-input type="password" v-model="passwordRepeat" minlength="6" maxlength="20"
                   placeholder="请重复您的登录密码" required/>
        </b-form-group>

        <div class="btn-area">
          <b-btn variant="primary" block type="submit">绑定手机号</b-btn>
        </div>
      </b-card>
    </form>
  </div>
</template>
<script>
export default {
  name: 'bindPhone',
  title: '绑定手机号',
  data() {
    return {
      phone: '',
      password: '',
      passwordRepeat: '',
      code: '',
      sendBtnText: '获取验证码',
      sendBtnVisible: true
    }
  },
  methods: {
    onSubmit() {
      if (this.passwordRepeat !== this.password) {
        return this.$alert.error('输入的两次密码不同，请重试')
      }
      if (this.password.length < 6) {
        return this.$alert.error('密码不能小于6位，请重新输入新密码')
      }

      const vipInfo = {
        loginPhone: this.phone,
        password: this.password,
        code: this.code
      }

      return this.$req.post('/api/user/vip/registration', vipInfo).then(() => {
        this.$alert.success('绑定手机成功')
        this.$router.push('/my')
      })
    },
    sendVerify() {
      if (!this.sendBtnVisible) {
        return
      }
      const telValid = this.validatePhone(this.phone)
      if (!telValid) {
        return this.$alert.warn('请输入正确的手机号')
      }
      // 检查手机号是否绑定过
      return this.$req.get(`/api/user/vip/phone_check?login_phone=${this.phone}`).then(data => {
        if (data.registered) {
          return this.$alert.error('当前手机号已注册绑定')
        }
        this.sendBtnText = '60 s'
        let count = 60
        this.sendBtnVisible = false
        // 发送验证码
        this.$req.post('/api/user/vip/validation_code', {loginPhone: this.phone}).then(() => {
          const interval = setInterval(() => {
            count--
            if (count === 0) {
              this.sendBtnText = '获取验证码'
              this.sendBtnVisible = true
              clearInterval(interval)
              return
            }
            this.sendBtnText = `${count} s`
          }, 1000)
        })
      })
    },
    // 验证手机号
    validatePhone(val) {
      const reg = /^(1[34578])\d{9}$/
      if (!val || !reg.test(val)) {
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
  .bind.content {
    max-width: 480px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
</style>
