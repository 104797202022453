<template>
  <div class="wallet">
    <page-title>我的钱包</page-title>
    <balance-view/>
    <coupons-view/>
    <redeem-codes-view/>
  </div>
</template>

<script>
import BalanceView from './Balance'
import CouponsView from './Coupons'
import RedeemCodesView from './RedeemCodes'

export default {
  name: 'wallet',
  title: '我的钱包',
  components: {BalanceView, CouponsView, RedeemCodesView}
}
</script>

<style scoped>

</style>
