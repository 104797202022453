<template>
  <div class="promotions">
    <h2 class="page-title">优惠活动（共 {{couponActivities.length}} 项）</h2>
    <template v-if="loading">
      <b-card class="activity invalid">
        <ske width="10em" block/>
        <div class="coupon-value">
          <ske width="1em" class="price"></ske>
          <ske width="1em"></ske>
        </div>
        <template slot="footer">
          <ske width="4em" class="float-right"/>
          <ske width="6em"/>
        </template>
      </b-card>
    </template>
    <template v-else>
      <div class="activities" v-if="list.length">
        <b-card :class="{invalid: item.exhausted || !item.hasStarted, claimed: item.get, today: item.isToday && !item.hasStarted}"
                :key="item.couponNo"
                class="activity"
                v-for="item in list">
          <b-row align-v="center">
            <b-col>
              <div class="name">{{item.name || ''}}</div>
              <div class="coupon-value">
                <span class="price">{{item.value.slice(0, -1)}}</span>
                <span>{{item.value.slice(-1)}}</span>
                <div class="small" v-if="item.minUsePrice">
                  满 {{item.minUsePrice}} 减 {{item.price}}
                </div>
              </div>
            </b-col>
            <b-col cols="auto" class="text-right">
              <div v-if="!item.hasExpired">剩余 {{item.remainTimes}} 张</div>
              <div v-if="!item.hasStarted">
                <datetime :value="item.startAt" suffix=" 开抢"/>
              </div>
              <div v-else-if="item.get">
                <fa icon="check"/>
                已领取
              </div>
              <div v-else-if="item.exhausted">已抢完</div>
              <div v-else-if="item.hasExpired">活动已结束</div>
              <b-btn size="lg"
                     @click="claimCoupon(item)"
                     variant="warning"
                     :disabled="claiming.includes(item)"
                     v-else>
                {{claiming.includes(item) ? '正在领取' : '马上领取'}}
              </b-btn>

              <div class="countdown" v-if="item.countdown">
                <div class="item day">{{item.countdown[0]}}</div>
                <div class="item hour">{{item.countdown[1]}}</div>
                <div class="item minute">{{item.countdown[2]}}</div>
                <div class="item second">{{('0' + item.countdown[3]).slice(-2)}}</div>
              </div>
            </b-col>
          </b-row>

          <div slot="footer" class="text-muted small">
            <span class="restrictions" v-if="item.hasRestriction">
              <span v-if="item.publishTypes.length">
                {{item.publishTypes | showPublishTypes}}
              </span>
              <span v-if="item.binding.length">{{item.binding | showBindings}}</span>
              <span v-if="item.products.length">{{item.products | showProducts}}</span>
            </span>
            <template v-else>无使用限制</template>
            <div class="float-right" v-if="item.couponExpireGap || item.couponExpireAt">
              <fa icon="clock"/>
              <template v-if="item.couponExpireAt">
                <datetime :value="item.couponExpireAt" suffix=" 过期"></datetime>
              </template>
              <template v-else>领取{{item.couponExpireGap}}天后过期</template>
            </div>
          </div>
        </b-card>
      </div>
      <div class="text-center" v-else>
        <p>
          <img src="https://static.weixinshu.com/assets/images/deer/cry.png!320"
               style="max-width: 200px;">
        </p>
        <p class="text-muted">好遗憾，没有相关的活动哦</p>
      </div>
    </template>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import route from '@/mixins/route-data'

export default {
  name: 'promotions',
  title: '优惠活动',
  mixins: [route('couponActivities')],
  data() {
    return {
      now: new Date(),
      claiming: []
    }
  },
  created() {
    this.interval = setInterval(this.onInterval, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    onLoad() {
      this.onInterval()
    },
    onInterval() {
      this.now = new Date()
      for (const coupon of this.couponActivities) {
        if (coupon.hasStarted) {
          continue
        }
        const startAt = coupon.startAt
        coupon.hasStarted = new Date(startAt) <= Date.now()
        if (coupon.hasStarted) {
          coupon.countdown = null
          continue
        }
        if (!startAt || this.$day(startAt).diff(this.now, 'hour') >= 24) {
          continue
        }
        this.$set(coupon, 'countdown', this.countDown(startAt))
      }
    },
    countDown(date) {
      const diffTime = Math.round(new Date(date) - this.now)

      const oneSecond = 1000
      const oneMinute = 60 * oneSecond
      const oneHour = 60 * oneMinute
      const oneDay = 24 * oneHour

      const days = Math.floor(diffTime / oneDay)
      const hours = Math.floor((diffTime - days * oneDay) / oneHour)
      const minutes = Math.floor((diffTime - days * oneDay - hours * oneHour) / oneMinute)
      const seconds = Math.floor((diffTime - days * oneDay - hours * oneHour - minutes *
        oneMinute) / oneSecond)

      return [days, hours, minutes, seconds]
    },
    async claimCoupon(coupon) {
      try {
        if (this.claiming.includes(coupon)) {
          return
        }
        this.claiming.push(coupon)
        await this.$req.get(`/api/user/activity_coupons/${coupon.couponNo}`)
        if (coupon.couponNo === 'U61QLJ') {
          this.$ls.set('feedback', 1)
        }
        this.$alert.success('优惠券领取成功')
        coupon.get = true
        this.$router.push('/my/wallet')
      } catch (err) {
        coupon.get = false
      } finally {
        this.claiming.splice(this.claiming.indexOf(coupon), 1)
        const result = await this.$ajax.fetchCoupon(coupon)
        Object.assign(coupon, result)
      }
    }
  },
  computed: {
    list() {
      return orderBy(this.couponActivities, ['exhausted', 'startAt'], ['asc', 'asc'])
    }
  }
}
</script>

<style lang="scss" scoped>
.promotions {
  .activity {
    margin-bottom: 1em;
    box-shadow: $shadow-base;
    background-color: $primary;

    &.invalid {
      background-color: #d9d9d9;
    }

    &.claimed {
      background-color: $success;
    }

    &.today {
      background-color: $warning;
    }

    .card-body, .card-footer {
      position: relative;
    }

    .card-body {
      padding-top: 1em;
      padding-bottom: 1em;
      color: #fff;
    }

    .card-footer {
      border-top: 0;
      background-color: #fff;
    }

    .restrictions {
      span + span {
        &:before {
          content: '/ ';
        }
      }
    }

    .coupon-value {
      white-space: nowrap;
      line-height: 1.2;

      .price {
        font-size: 3.5em;
      }
    }

    .name {
      max-width: 10em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .card-footer:after {
      content: '';
      display: block;
      position: absolute;
      top: -4px;
      left: 0;
      width: 100%;
      height: 4px;
      background-image: url('../../assets/images/coupon-border.png');
      background-repeat: repeat-x;
      background-position: 0 0;
      background-size: 12px 4px;
    }
  }

  .countdown {
    margin-top: .25em;

    .item {
      opacity: .9;
      background-color: #fff;
      display: inline-block;
      padding: 4px;
      line-height: 1;
      border-radius: 2px;
      font-size: 12px;
      color: #333;
    }

    .day:after {
      content: '天';
    }

    .hour:after {
      content: '时';
    }

    .minute:after {
      content: '分';
    }

    .second:after {
      content: '秒';
    }
  }
}
</style>
