<template>
  <div class="my" :data-route="$route.name">
    <div class="wrapper" v-if="$route.name === 'my'">
      <b-card class="user-info">
        <b-row align-v="center" v-if="user">
          <b-col cols="auto">
            <b-link to="/my">
              <avatar :alt="user.openid" :src="user.avatar" size="4.5em"></avatar>
            </b-link>
          </b-col>
          <b-col style="min-width: 0;">
            <h5 class="text-overflow mb-0">{{user.nickname}}</h5>
            <div style="font-size: 1.25em;">
              <b-badge v-if="isAgent" variant="success">代理</b-badge>
              <ske width="6em" v-if="!grades.length"/>
              <template v-else>
                <b-badge variant="secondary">VIP {{userLevel}}</b-badge>

                <b-badge v-if="discount >= 10">无折扣</b-badge>
                <b-badge variant="primary" v-else>{{discount}} 折</b-badge>
              </template>
            </div>
            <div class="small" v-if="user.grade > 0">
              <b-link to="/my/vip">
                <fa icon="crown"/>
                我的会员
              </b-link>
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row align-v="center">
          <b-col cols="auto">
            <b-link to="/my/wallet">
              <span class="text-body">优惠券</span>
              <ske width="2.5em" v-if="couponsCount === null"/>
              <span class="text-primary" v-else>{{couponsCount}} 张</span>
            </b-link>
          </b-col>
          <divider vertical style="height: 2em;"></divider>
          <b-col>
            <b-link to="/my/wallet">
              <span class="text-body">余额</span>
              <ske width="3em" v-if="balance === null"/>
              <span class="text-primary" v-else>{{$rmb(balance)}}</span>
            </b-link>
          </b-col>
          <b-col cols="auto">
            <b-btn variant="primary" to="/my/recharge" size="sm">
              <fa icon="coins"/>
              充值
            </b-btn>
          </b-col>
        </b-row>
      </b-card>
      <div class="menu">
        <b-card no-body>
          <cell-link id="referral" to="/my/referral">
            <img src="https://img.xinshu.me/upload/242f735aac97491481d4cff44fcb682f"
                 style="width: 2.5em;"
                 slot="icon"/>
            <div>我是代言人</div>
            <div class="text-muted">
              好友下单您可获得
              <ske v-if="!ratio" width="2em"/>
              <span class="text-primary" v-else>{{ratio | percent}}</span>
              提成哦
            </div>
          </cell-link>
        </b-card>

        <b-card no-body v-if="!isAgent">
          <!-- <cell-link title="我的七周年奖品" to="/promotions/7th-prize">
            <img slot="icon" src="https://static.weixinshu.com/assets/emotions/37-20e3.png"
                 style="height: 20px;">
          </cell-link> -->
          <cell-link title="打卡福利" href="https://weixinshu.com/pages/daka-benefit/">
            <img slot="icon" src="https://static.weixinshu.com/assets/emotions/2764.png"
                 style="height: 20px;">
          </cell-link>
        </b-card>

        <b-card no-body v-if="!isAgent && $day().isBefore('2020-12-01')">
          <cell-link title="6 周年庆 6 折购书" to="/promotions/6th-anniversary-groupon">
            <img slot="icon" src="https://static.weixinshu.com/assets/emotions/36-20e3.png"
                 style="height: 20px;">
          </cell-link>
        </b-card>

        <b-card no-body>
          <cell-link title="优惠券活动" to="/my/promotions" v-if="couponActivities.length">
            <img slot="icon" src="https://static.weixinshu.com/assets/emotions/1f389.png"
                 style="height: 20px;">
            <b-badge slot="badge" variant="primary">HOT</b-badge>
          </cell-link>
          <cell-link title="我的订单" to="/orders" icon="credit-card"/>
          <div class="divider"></div>
          <cell-link title="绑定手机号" to="/my/bind-phone" icon="mobile" v-if="!user.isVip"/>
          <cell-link title="修改密码" to="/my/reset-password" icon="lock" v-else/>
          <cell-link title="客服/反馈" to="/my/feedback" icon="headset"/>

          <cell-link @click.native="logout" title="退出登录" icon="sign-out"/>
        </b-card>

        <form @submit.prevent="runCode" class="debug mt-4" v-if="debug">
          <textarea class="form-control mb-3 text-monospace"
                    id="debugTextarea" placeholder="Javascript"
                    v-model="code"></textarea>
          <b-row>
            <b-col>
              <b-btn type="submit" block>Run</b-btn>
            </b-col>
            <b-col cols="auto">
              <b-btn block type="reset" @click="debugResult = ''; code = ''">Clear</b-btn>
            </b-col>
          </b-row>
          <b-card class="code" v-if="debugResult">{{debugResult}}</b-card>
        </form>

        <div class="text-center mt-2" style="opacity: 0;">
          <b-link @dblclick="toggleDebug">DEBUG</b-link>
        </div>
      </div>
    </div>

    <template v-else>
      <router-view class="wrapper"/>
      <div class="wrapper btn-area" v-if="!$route.name.includes('recharge')">
        <b-btn block variant="link" to="/my" v-if="$route.path.split('/').length === 3">
          返回个人中心
        </b-btn>
        <b-btn block variant="link" @click="$router.go(-1)" v-else>返回</b-btn>
      </div>
    </template>
  </div>

</template>

<script>
import { round } from 'lodash'

export default {
  name: 'my',
  title: '个人中心',
  data() {
    return {
      code: '',
      debugResult: '',
      grades: [],
      balance: null,
      couponsCount: null,
      couponActivities: [],
      ratio: 0
    }
  },
  computed: {
    userLevel() {
      return this.user?.grade
    },
    discount() {
      return round(this.grades.find(i => i.grade === this.userLevel)?.discount * 10, 1)
    }
  },
  created() {
    this.$ajax.fetchUserInfo().then(user => {
      this.$store.commit('setUser', user)
    })
    this.$ajax.fetchGradeInfo().then(result => {
      this.grades = result.normalUser
    })
    this.$ajax.fetchBalance().then(result => {
      this.balance = result.balance
    })
    this.$ajax.fetchCouponsCount().then(result => {
      this.couponsCount = result
    })
    this.$ajax.fetchCouponActivities({}).then(result => {
      this.couponActivities = result
    })
    this.$ajax.fetchUserProfile().then(result => {
      this.ratio = result.withdrawRatio
    })
  },
  methods: {
    toggleDebug() {
      if (window.DEBUG) {
        sessionStorage.removeItem('debug')
        this.$alert.info('Debug mode disabled')
      } else {
        sessionStorage.setItem('debug', 1)
        this.$alert.info('Debug mode enabled')
      }
      setTimeout(() => {
        location.reload()
      }, 1000)
    },
    runCode() {
      // eslint-disable-next-line no-eval
      this.debugResult = eval(this.code)
      this.$alert.success('代码已运行')
    },
    async logout() {
      const confirmed = await this.$dialog.confirm({
        title: '退出微信书',
        content: '是否要退出微信书',
        okTitle: '退出'
      })
      if (!confirmed) {
        return
      }
      this.$store.commit('resetAuth')
      this.$alert.success('账号已登出')
      this.$router.push('/signin')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  max-width: 560px;
  min-height: 0;
}

.my {
  max-width: 100%;

  &[data-route="my"]:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 110px;
    background-color: $primary;
    z-index: -1;
    background-image: url(https://img.xinshu.me/upload/a7cd7a52cd38418fad403b732d976e57);
    background-repeat: no-repeat;
    background-size: cover;

    @include media-breakpoint-up(sm) {
      height: 120px;
    }
  }

  ::v-deep .card {
    overflow: hidden;
  }
}

.code {
  margin-top: 1rem;
  word-break: break-all;
  white-space: pre-wrap;
  font-family: $font-family-monospace;
}
</style>
