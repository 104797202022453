<template>
  <div class="level">
    <page-title>
      我的会员
      <template slot="right">
        <b-link v-b-modal="'levels'">查看等级说明</b-link>
      </template>
    </page-title>
    <loading v-if="loading"/>
    <template v-else>
      <div class="level-card">
        <div class="content">
          <div class="logo" @click="clearCache">
            <img src="@/assets/images/vip/logo.svg">
          </div>
          <div class="row">
            <div class="col">
              <div class="tag-name">当前等级</div>
              <h4>VIP {{level.grade}}</h4>
              <div class="small">累计消费 {{user.paidMoneySum | round(2)}} 元</div>
            </div>
            <div class="col">
              <div class="tag-name">当前折扣</div>
              <h4 v-if="level.grade">{{level.discount * 10 | discount}}</h4>
              <h4 v-else>暂无折扣</h4>
            </div>
            <div class="col">
              <template v-if="nextLevel">
                <div class="tag-name">下一等级</div>
                <h4>VIP {{nextLevel.grade}}</h4>
                <div class="small">
                  再消费 {{upgradeAmount}} 元
                  <br>
                  可享 <b>{{nextLevel.discount * 10 | discount}}</b> 优惠
                </div>
              </template>
              <template v-else>
                <div class="tag-name">已达最高等级</div>
                <h4>
                  <fa icon="crown"/>
                </h4>
                <div class="small">
                  <fa icon="check"/>
                  您已解锁最低折扣
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <b-modal id="levels" title="等级说明" hide-footer>
        <p>以下为用户等级说明</p>
        <table class="table">
          <thead>
          <tr>
            <th style="width: 4em;">等级</th>
            <th>条件</th>
            <th>折扣</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="level in levels.filter(l => l.grade > 0)" :key="level.grade"
              :class="{active: level.grade === userGrade}">
            <td>VIP {{level.grade}}</td>
            <td>历史{{user.isAgent ? '订单' : '消费'}}满{{level.sum | round}}元</td>
            <td>{{level.discount * 10 | round}}折</td>
          </tr>
          </tbody>
        </table>
      </b-modal>
    </template>
  </div>
</template>
<script>
import route from '@/mixins/route-data'
import { get, round } from 'lodash'

export default {
  name: 'vip',
  title: '我的会员',
  mixins: [route('gradeInfo')],
  computed: {
    userGrade() {
      return get(this.user, 'grade') || 0
    },
    levels() {
      return this.gradeInfo.normalUser
    },
    level() {
      return this.levels.find(item => item.grade === this.userGrade) || {grade: 0, discount: 1, sum: 0}
    },
    nextLevel() {
      return this.levels.find(item => item.grade === this.userGrade + 1)
    },
    upgradeAmount() {
      try {
        return round(Math.max(this.nextLevel.sum - this.user.paidMoneySum, 0), 2)
      } catch (err) {
        return 0
      }
    }
  },
  methods: {
    async clearCache() {
      const confirmed = await this.$dialog.confirm({
        title: '清理缓存',
        content: '恭喜你发现了隐藏功能！<br>点击确定清理本地缓存，所有已存的地址、发票、提示开关将会被重置。',
        okTitle: '确定'
      })

      if (!confirmed) {
        return
      }
      localStorage.clear()
      sessionStorage.clear()
      this.$alert.success('缓存已清理')
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
  .level {
    max-width: 420px;
    padding-top: 1rem;
    @include media-breakpoint-down(sm) {
      padding-top: .5rem;
      padding-left: 10px;
      padding-right: 10px;
    }

    .level-card {
      max-width: 420px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      border-radius: 10px;
      color: #fff;
      box-sizing: border-box;
      background: linear-gradient(to bottom, #FF4669, #FD6464);
      box-shadow: $box-shadow;
      text-align: center;

      .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.5rem;
        background-image: url('../../assets/images/vip/background.svg');
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      &:after {
        display: block;
        content: '';
        padding-bottom: 66.7%;
      }

      .header {
        position: relative;
        overflow: hidden;
        padding-top: 1em;
      }

      .logo {
        position: absolute;
        top: 1em;
        right: 0;
        padding: .5em 1em;
        background: rgba(255, 255, 255, .4);
        text-align: center;
        border-radius: 25px 0 0 25px;

        img {
          width: 20px;
        }
      }

      .tag-name {
        margin-bottom: 1em;
        @include media-breakpoint-down(sm) {
          margin-bottom: .5em;
        }
      }

      h4 {
        margin-bottom: 1.5em;
        @include media-breakpoint-down(sm) {
          margin-bottom: .8em;
          font-size: 1.3em;
        }
      }
    }

    tr.active {
      color: $primary;
      font-weight: bold;
    }
  }
</style>
